<template>
  <el-dialog
    title="Delete Orders"
    :visible.sync="shouldShow"
    append-to-body
    @close="closeEvent"
    width="30%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <el-radio-group v-model="type" class="eden-switch-radio small">
          <el-radio-button label="all">All time orders</el-radio-button>
          <el-radio-button label="some">Some orders</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item label="Service">
            <el-select v-model="form.service" placeholder="Select service">
              <el-option label="All" value="all"></el-option>
              <el-option
                v-for="(service, index) in services"
                :key="index"
                :label="`${formatText(service)}`"
                :value="service"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="type === 'some'"
        type="flex"
        :gutter="10"
        class="is-flex-wrap"
      >
        <el-col :span="12">
          <el-form-item label="Start date">
            <el-date-picker
              v-model="form.start_date"
              value-format="yyyy-MM-dd"
              type="date"
              format="dd MMM, yyyy"
              prefix-icon="eden-icon--calendar"
              placeholder="Select start date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="End date">
            <el-date-picker
              v-model="form.end_date"
              value-format="yyyy-MM-dd"
              type="date"
              format="dd MMM, yyyy"
              :picker-options="{
                disabledDate: disableStartDate,
              }"
              prefix-icon="eden-icon--calendar"
              placeholder="Select end date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="danger" :loading="deleting" @click="deleteOrders"
        >Delete</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerOrdersDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      type: "all",
      form: {
        service: "",
        start: "",
        end: "",
      },
      deleting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return parseInt(this.$route.params.id);
    },
    services() {
      const { plan } = this.customer.next_billing_date[0];
      return Object.keys(plan);
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    disableStartDate(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }

      return time.getTime() <= new Date(this.form.start_date).getTime();
    },
    deleteOrders() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.deleting = true;

        let payload;

        if (this.type === "some") {
          payload = {
            ...this.form,
          };
        } else {
          payload = {
            service: this.form.service,
          };
        }

        customer
          .ordersDelete(this.type, this.customerId, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
              this.deleting = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.deleting = false;
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
